import numeral from 'numeral'
import { SOLD, RENTED } from '../Common/Constants/PropertyConstants'

export function numberToCurrency (price) {
  price = parseFloat(price);

  if (!isFinite(price) || (price === null)) return '--';

  while ( /(\d+)(\d{3})/.test(price.toString()) ) {
    price = price.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2');
  }
  return `$${price}`;
}

export function currencyToNumber (price) {
  if ( typeof price === 'string' ) {
    return Number(price.replace(/\$/g, '').split(',').join(''));
  }
  return price;
}

export function shortPrice (price, precision = 1) {
  let stringInput, leadingDigits;

  if ( !price ) {
    return 'Inquire';
  } else if ( price && parseInt(price) === 0) {
    return 'Inquire'
  } else if ( price && price.toString().length < 4 ) {
    return '$' + price;
  } else if ( price && price.toString().length < 7 ) {
    stringInput = price.toString();
    leadingDigits = stringInput.split('').slice(0, stringInput.length - (3 - precision));
    leadingDigits.splice(leadingDigits.length - precision, 0, '.');
    leadingDigits = parseFloat(leadingDigits.join(''));
    return '$' + leadingDigits + 'K';
  } else if ( price && price.toString().length < 10 ) {
    stringInput = price.toString();
    leadingDigits = stringInput.split('').slice(0, stringInput.length - (6 - precision));
    leadingDigits.splice(leadingDigits.length - precision, 0, '.');
    leadingDigits = parseFloat(leadingDigits.join(''));
    return '$' + leadingDigits + 'M';
  } else {
    stringInput = price.toString();
    leadingDigits = stringInput.split('').slice(0, stringInput.length - (9 - precision));
    leadingDigits.splice(leadingDigits.length - precision, 0, '.');
    leadingDigits = parseFloat(leadingDigits.join(''));
    return '$' + leadingDigits + 'B';
  }
}

export function shortNumber (number, precision = 1) {
  let stringInput, leadingDigits;

  if (number.toString().length < 4) {
    return number;
  } else if (number.toString().length < 7) {
    stringInput = number.toString();
    leadingDigits = stringInput.split('').slice(0, stringInput.length - (3 - precision));
    leadingDigits.splice(leadingDigits.length - precision, 0, '.');
    leadingDigits = parseFloat(leadingDigits.join(''));
    return leadingDigits + 'K';
  } else if ( number.toString().length < 10 ) {
    stringInput = number.toString();
    leadingDigits = stringInput.split('').slice(0, stringInput.length - (6 - precision));
    leadingDigits.splice(leadingDigits.length - precision, 0, '.');
    leadingDigits = parseFloat(leadingDigits.join(''));
    return leadingDigits + 'M';
  } else {
    stringInput = number.toString();
    leadingDigits = stringInput.split('').slice(0, stringInput.length - (9 - precision));
    leadingDigits.splice(leadingDigits.length - precision, 0, '.');
    leadingDigits = parseFloat(leadingDigits.join(''));
    return leadingDigits + 'B';
  }
}

export const priceWithFallback = (price, fallback="Inquire for Price") => {
  if(price && parseInt(price) > 0){
    return numeral(price).format('$0,0')
  }else{
    return fallback
  }
}

export const calculateDisplayPrices = (listing, fallback="Inquire for Price") => {
  const isSold = [SOLD, RENTED].includes(listing.simple_listing_status);

  const displayPrice = (isSold && !!listing.sold_price) ? listing.sold_price : listing.list_price;

  let showListedAtPrice = isSold && !!listing.sold_price && !!listing.list_price;
  const listedAtPrice = listing.list_price;

  const formattedDisplayPrice = priceWithFallback(displayPrice, fallback)
  const formattedListedAtPrice = listedAtPrice ? numeral(listedAtPrice).format('$0,0') : null;

  return({
    formattedDisplayPrice,
    formattedListedAtPrice,
    displayPrice,
    listedAtPrice,
    showListedAtPrice,
    isSold
  })

}

export const calculatePercentChange = function(from, to, precision=1) {
  from = parseFloat(from);
  to = parseFloat(to);

  if (!isFinite(from) || !isFinite(to)) return '--%';

  let percentChange = (((to - from) / from) * 100);

  if (!isFinite(percentChange)) return '--%';

  try {
    percentChange = percentChange.toFixed(precision)
  } catch (e) {}

  return `${percentChange}%`;
};
