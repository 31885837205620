export const DEFAULT_COMMUTE_TYPE = 'DRIVE';

export const COMMUTE_TYPES = [
  {label: 'Drive', value: DEFAULT_COMMUTE_TYPE},
  {label: 'Drive (Rush Hour)', value: 'DRIVE_RUSH_HOUR'},
  {label: 'Walk', value: 'WALK'},
  {label: 'Bike', value: 'BIKE'},
  {label: 'Transit', value: 'TRANSIT'},
]


export const friendlyCommuteType = (commuteType) => {
  const commutePicklistValue = COMMUTE_TYPES.find((commuteOption) => commuteOption.value === commuteType);
  return commutePicklistValue ? commutePicklistValue.label : commuteType;
};
