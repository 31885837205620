import PhotoCircle from './PhotoCircle';
import {
  IoIosClose
} from "react-icons/io";
import { Component } from 'react';

class UnsentAttachedListing extends Component {


  render() {
    const attachedListing = this.props.attachedListing;
    const photoUrl = attachedListing.photos.edges[0].node.photo_url;
    return (
      <div style={{position: 'relative'}} className="unsent-attached-listing">
        <PhotoCircle photoUrl={photoUrl}/>
        <button
            className="badge badge-danger"
            style={{position: 'absolute', borderRadius: 10, width: 15, height: 15, top: 0, right: 0, padding: 0, paddingLeft: 1}}
            onClick={() => this.props.removeListing(this.props.attachedListing)}
        >
          <IoIosClose
              size={10}
          />
        </button>
      </div>
    )
  }
}

export default UnsentAttachedListing;
