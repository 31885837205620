import { useSnackbar } from 'notistack'
import { gql, useMutation } from '@apollo/client'
import { MenuItem, Select, InputLabel, FormControl } from '@mui/material'
import {AGENT_SCHEDULE_OPTIONS, CLIENT_SCHEDULE_OPTIONS} from '../Constants/HomeReportConstants'

const UPDATE_HOME_REPORT_MUTATION = gql`
  mutation updateHomeReport($input: UpdateHomeReportInput!) {
    update_home_report(input: $input) {
      home_report {
        id
        schedule
        next_send_at
      }
      errors {
        key
        message
      }
    }
  }
`

const HomeReportScheduleSelect = ({ homeReport, label, isClientFacing }) => {
  const { enqueueSnackbar } = useSnackbar()

  const options = isClientFacing ? CLIENT_SCHEDULE_OPTIONS : AGENT_SCHEDULE_OPTIONS

  const [updateHomeReport, { loading }] = useMutation(UPDATE_HOME_REPORT_MUTATION, {
    onError: () =>
      enqueueSnackbar('Error: Unable to update Home Value Alert.', {
        variant: 'error',
        autoHideDuration: 3000,
      }),
    onCompleted: data =>
      data.update_home_report && data.update_home_report.errors
        ? enqueueSnackbar('Error: Unable to update Home Value Alert.', {
            variant: 'error',
            autoHideDuration: 3000,
          })
        : enqueueSnackbar('Home Value Alert Updated', {
            variant: 'success',
            autoHideDuration: 3000,
          }),
  })

  return (
    <FormControl fullWidth>
      {label && <InputLabel id="home-report-schedule-select-label">{label}</InputLabel>}
      <Select
        id="home-report-schedule-select"
        displayEmpty
        label={label}
        value={homeReport.schedule}
        size="small"
        fullWidth
        disabled={loading}
        onChange={e =>
          updateHomeReport({
            variables: {
              input: {
                id: homeReport.id,
                schedule: e.target.value,
              },
            },
          })
        }
      >
        {options.map(opt => (
          <MenuItem key={opt.value} value={opt.value}>
            {opt.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default HomeReportScheduleSelect
