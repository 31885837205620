import React from 'react'
import PhotoCircle from '../../../Common/Conversations/PhotoCircle'
import { IoIosCheckmark, IoIosClose } from 'react-icons/io'
import { withApollo } from '@apollo/client/react/hoc'
import { Mutation } from '@apollo/client/react/components'

import { gql } from '@apollo/client'
import { toast } from 'react-toastify'
import ErrorBoundarySimple from '../../../Common/ErrorBoundarySimple'
import { Component } from 'react'
import { AgentAnalyticsClass } from '../../../Common/AnalyticClasses'
import { Typography } from '@mui/material'

const UPDATE_REVERSE_PROSPECT_REQUEST_MUTATION = gql`
  mutation updateReverseProspectRequest($data: UpdateReverseProspectRequestInput!) {
    update_reverse_prospect_request(input: $data) {
      reverse_prospect_request {
        id
        state
      }
    }
  }
`

class ReverseProspectRequestAttachment extends Component {
  componentDidMount() {
    if (!this.props.isMessageSentByCurrent && this.props.reverseProspectingRequest.state === null) {
      this.props.client
        .mutate({
          mutation: UPDATE_REVERSE_PROSPECT_REQUEST_MUTATION,
          variables: {
            data: {
              reverse_prospect_request_id: this.props.reverseProspectingRequest.id,
              state: 'viewed',
            },
          },
        })
        .then(({ data }) => {})
        .catch(e => {
          console.log(e)
        })
    }
  }

  renderSenderMessage() {
    switch (this.props.reverseProspectingRequest.state) {
      case 'viewed':
        return 'Agent has viewed this message'
      case 'interested':
        return "Agent's clients may be interested"
      case 'uninterested':
        return "Agent's clients are not interested"
      default:
        return 'Agent has not yet responded or viewed this message'
    }
  }

  renderSelector() {
    if (this.props.isMessageSentByCurrent) {
      return (
        <div className="row">
          <div className="col d-flex flex-row justify-content-center align-items-center pt-2">
            <Typography color="textPrimary" variant="body1">
              {this.renderSenderMessage(this.props.reverseProspectingRequest)}
            </Typography>
          </div>
        </div>
      )
    }

    if (this.props.reverseProspectingRequest.state === 'interested') {
      return (
        <div className="row">
          <div className="col d-flex flex-row justify-content-center align-items-center pt-2 text-success">
            <IoIosCheckmark style={{ fontSize: '30px' }} />
            <strong>Yes, relevant to my clients</strong>
          </div>
        </div>
      )
    } else if (this.props.reverseProspectingRequest.state === 'uninterested') {
      return (
        <div className="row">
          <div className="col d-flex flex-row justify-content-center align-items-center pt-2 text-danger">
            <IoIosClose style={{ fontSize: '30px' }} />
            No thanks
          </div>
        </div>
      )
    }

    return (
      <div className="row">
        <div className="col border-right pt-2">
          <Mutation
            mutation={UPDATE_REVERSE_PROSPECT_REQUEST_MUTATION}
            variables={{
              data: {
                reverse_prospect_request_id: this.props.reverseProspectingRequest.id,
                state: 'interested',
              },
            }}
            onCompleted={data => {
              if (
                data.update_reverse_prospect_request &&
                data.update_reverse_prospect_request.reverse_prospect_request
              ) {
                toast.success(`Agent has been notified`)
              } else {
                toast.error('There has been an error, please try again')
              }
            }}
          >
            {(updateReverseProspectRequest, { loading }) => {
              return (
                <button
                  className={`btn btn-outline-success borderless w-100 h-100 d-flex flex-row justify-content-center align-items-center ${AgentAnalyticsClass(
                    'clients-are-interested-click',
                    'conversation-reverse-prospect'
                  )}`}
                  onClick={() => updateReverseProspectRequest()}
                  disabled={loading}
                >
                  <IoIosCheckmark style={{ fontSize: '30px' }} />
                  <strong>Yes, relevant to my clients</strong>
                </button>
              )
            }}
          </Mutation>
        </div>
        <div className="col border-left pt-2">
          <Mutation
            mutation={UPDATE_REVERSE_PROSPECT_REQUEST_MUTATION}
            variables={{
              data: {
                reverse_prospect_request_id: this.props.reverseProspectingRequest.id,
                state: 'uninterested',
              },
            }}
            onError={() => toast.error('There has been an error, please try again')}
            onCompleted={data => {
              if (
                data.update_reverse_prospect_request &&
                data.update_reverse_prospect_request.reverse_prospect_request
              ) {
                toast.success(`Agent has been notified`)
              } else {
                toast.error('There has been an error, please try again')
              }
            }}
          >
            {(updateReverseProspectRequest, { loading }) => {
              return (
                <button
                  className={`btn btn-outline-danger borderless w-100 h-100 d-flex flex-row justify-content-center align-items-center ${AgentAnalyticsClass(
                    'clients-are-not-interested-click',
                    'conversation-reverse-prospect'
                  )}`}
                  onClick={() => updateReverseProspectRequest()}
                  disabled={loading}
                >
                  <IoIosClose style={{ fontSize: '30px' }} />
                  No thanks
                </button>
              )
            }}
          </Mutation>
        </div>
      </div>
    )
  }

  render() {
    const listing = this.props.reverseProspectingRequest.property
    const photoUrl = listing.photos.edges[0].node.photo_url

    return (
      <ErrorBoundarySimple isAgentErrorBoundary>
        <div
          className={`bg-lighter rounded mt-2 py-2 px-3 ${
            this.props.isMessageSentByCurrent ? 'mr-3' : 'ml-3'
          }`}
        >
          <div className="row border-bottom">
            <div className="col my-2">
              <a
                className={`propertyClick d-flex flex-row justify-content-center align-items-center ${AgentAnalyticsClass(
                  'reverse-prospect-listing-click',
                  'conversation-reverse-prospect'
                )}`}
                id={`property_${listing.id}`}
                href={
                  this.props.isMessageSentByCurrent
                    ? '#'
                    : `/agent/ls/${listing.id}?current_tab=my_buyers`
                }
              >
                <PhotoCircle photoUrl={photoUrl} additionalClasses={'mr-3'} />
                {listing.address}
              </a>
            </div>
          </div>
          {this.renderSelector()}
        </div>
      </ErrorBoundarySimple>
    )
  }
}

export default withApollo(ReverseProspectRequestAttachment)
