import moment from 'moment-timezone';
import TimeFormatter from "./TimeUtils";

const determineIfIncompleteDataPoint = (from, to, metricInterval, index, collectionLength) => {
  let isIncompleteDataPoint = false;
  const isFirstDataPoint = index === 0;
  const isLastDataPoint = index === collectionLength - 1;

  const selectedStart = moment(from).startOf('day');
  const selectedEnd = moment(to).startOf('day');

  if (metricInterval === 'DAY') {
    // Today would be only incomplete data point
    const today = moment().startOf('day');
    if (isLastDataPoint && selectedEnd.isSame(today)) {
      isIncompleteDataPoint = true;
    }

  } else if (metricInterval === 'WEEK') {
    // Use isoWeek to ensure Monday is start of week
    const startOfWeek = moment(from).startOf('isoWeek').startOf('day');
    const endOfWeek = moment(to).endOf('isoWeek').startOf('day');

    if (
      (isFirstDataPoint && !selectedStart.isSame(startOfWeek))
      || (isLastDataPoint && !selectedEnd.isSame(endOfWeek))
    ) {
      isIncompleteDataPoint = true;
    }
  } else if (metricInterval === 'MONTH') {
    const startOfMonth = moment(from).startOf('month').startOf('day');
    const endOfMonth = moment(to).endOf('month').startOf('day');

    if (
      (isFirstDataPoint && !selectedStart.isSame(startOfMonth))
      || (isLastDataPoint && !selectedEnd.isSame(endOfMonth))
    ) {
      isIncompleteDataPoint = true;
    }
  }
  return isIncompleteDataPoint
};

const isSelectedEndInIncompleteInterval = (to, metricInterval) => {
  const selectedEnd = moment(to).startOf('day');

  if (metricInterval === 'DAY') {
    // For DAY interval, only incomplete data is today
    const today = moment().startOf('day');

    return selectedEnd.isSame(today);

  } else if (metricInterval === 'WEEK') {
    // For WEEK interval, incomplete data can be current week
    const currentWeek = moment().startOf('isoWeek').startOf('day');
    const isEndInCurrentWeek = currentWeek.isSame(selectedEnd.startOf('isoWeek').startOf('day'));

    // Or by choosing a selected end date in the middle of a week
    const endOfSelectedWeek = moment(to).endOf('isoWeek').startOf('day');

    return isEndInCurrentWeek || !selectedEnd.isSame(endOfSelectedWeek);

  } else if (metricInterval === 'MONTH') {
    // For MONTH interval, incomplete data can be current month
    const currentMonth = moment().startOf('month').startOf('day');
    const isEndInCurrentMonth = currentMonth.isSame(selectedEnd.startOf('month').startOf('day'));

    // Or by choosing a selected end date in the middle of the month
    const endOfSelectedMonth = moment(to).endOf('month').startOf('day');

    return isEndInCurrentMonth || !selectedEnd.isSame(endOfSelectedMonth);
  }
};

const formatXAxisLabel = (startTime, selectedStart, metricInterval, isFirstDataPoint, timezone) => {
  const startTimeOptions = {
    'DAY': TimeFormatter.formatUnix(startTime, 'monthDayNumber', timezone),
    'WEEK': `Week of ${TimeFormatter.formatUnix(startTime, 'monthDayNumber', timezone)}${(isFirstDataPoint && moment.unix(startTime).isBefore(moment(selectedStart))) ? '*' : ''}`,
    'MONTH': TimeFormatter.formatUnix(startTime, 'month', timezone),
  };
  return startTimeOptions[metricInterval]
};

const formatTooltipTimeLabel = ({startTime, selectedStart, selectedEnd, metricInterval, isFirstDataPoint, isLastDataPoint, timezone }) => {
  const shouldShowShorterEndRange = isSelectedEndInIncompleteInterval(selectedEnd, metricInterval);
  let label = TimeFormatter.formatUnix(startTime, 'monthDayNumber', timezone);

  if (metricInterval === 'WEEK') {
    const endOfWeek = moment.unix(startTime).tz(timezone).endOf('isoWeek').startOf('day');

    if (isLastDataPoint && shouldShowShorterEndRange) {
      label = `${TimeFormatter.formatUnix(startTime, 'monthDayNumber', timezone)} - ${TimeFormatter.format(selectedEnd, 'monthDayNumber', timezone)}`
    } else if (isFirstDataPoint && moment.unix(startTime).isBefore(moment(selectedStart))) {
      label = `${TimeFormatter.format(selectedStart, 'monthDayNumber', timezone)} - ${TimeFormatter.format(endOfWeek, 'monthDayNumber', timezone)}`
    } else {
      label = `${TimeFormatter.formatUnix(startTime, 'monthDayNumber', timezone)} - ${TimeFormatter.format(endOfWeek, 'monthDayNumber', timezone)}`
    }
  } else if (metricInterval === 'MONTH') {
    const endOfMonth = moment.unix(startTime).endOf('month').startOf('day');

    if (isLastDataPoint && shouldShowShorterEndRange) {
      label = `${TimeFormatter.formatUnix(startTime, 'monthDayNumber', timezone)} - ${TimeFormatter.format(selectedEnd, 'dayNumber', timezone)}`
    } else {
      label = `${TimeFormatter.formatUnix(startTime, 'monthDayNumber', timezone)} - ${TimeFormatter.format(endOfMonth, 'dayNumber', timezone)}`
    }

  }
  return label;
};

export {
  determineIfIncompleteDataPoint,
  isSelectedEndInIncompleteInterval,
  formatXAxisLabel,
  formatTooltipTimeLabel
}
