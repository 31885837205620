import { useState } from 'react'

export const useVerticalScrollWithShadow = () => {
  const [scrollTop, setScrollTop] = useState(0)
  const [scrollHeight, setScrollHeight] = useState(0)
  const [clientHeight, setClientHeight] = useState(0)

  const onVerticalScrollHandler = event => {
    setScrollTop(event.target.scrollTop)
    setScrollHeight(event.target.scrollHeight)
    setClientHeight(event.target.clientHeight)
  }

  const getBoxShadow = () => {
    const isBottom = clientHeight === Math.ceil(scrollHeight - scrollTop)
    const isTop = scrollTop === 0
    const isBetween = scrollTop > 0 && clientHeight < scrollHeight - scrollTop

    let boxShadow = 'none'
    const top = 'inset 0px 25px 25px -25px rgb(200 200 200 / 1)'
    const bottom = 'inset 0px -25px 25px -25px rgb(200 200 200 / 1)'

    if (isTop) {
      boxShadow = bottom
    } else if (isBetween) {
      boxShadow = `${top}, ${bottom}`
    } else if (isBottom) {
      boxShadow = top
    }
    return boxShadow
  }

  return { verticalBoxShadow: getBoxShadow(), onVerticalScrollHandler }
}

export const useHorizontalScrollWithShadow = () => {
  const [scrollLeft, setScrollLeft] = useState(0)
  const [scrollWidth, setScrollWidth] = useState(0)
  const [clientWidth, setClientWidth] = useState(0)

  const onHorizontalScrollHandler = event => {
    setScrollLeft(event.target.scrollLeft)
    setScrollWidth(event.target.scrollWidth)
    setClientWidth(event.target.clientWidth)
  }

  const getBoxShadow = () => {
    const isRight = clientWidth === Math.ceil(scrollWidth - scrollLeft)
    const isLeft = scrollLeft === 0
    const isBetween = scrollLeft > 0 && clientWidth < scrollWidth - scrollLeft

    let boxShadow = 'none'
    const left = 'inset 25px 0px 25px -25px rgb(200 200 200 / 1)'
    const right = 'inset -25px 0px 25px -25px rgb(200 200 200 / 1)'

    if (isLeft) {
      boxShadow = right
    } else if (isBetween) {
      boxShadow = `${left}, ${right}`
    } else if (isRight) {
      boxShadow = left
    }
    return boxShadow
  }

  return { horizontalBoxShadow: getBoxShadow(), onHorizontalScrollHandler }
}
