import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Component } from 'react';

class PhotoLightbox extends Component {
  static getDerivedStateFromProps(props, state) {
    const updatedState = {};

    if (!state.sortedPhotos) {
      // Initialize and sort photos
      updatedState.sortedPhotos = props.photos.edges.reduce((acc, currentPhoto) => {
        const roomName = currentPhoto.node.room_name;
        if (acc[roomName]) {
          acc[roomName].push(currentPhoto.node);
        } else if (roomName) {
          acc[roomName] = [currentPhoto.node];
        }
        return acc;
      }, { All: props.photos.edges.map(photo => photo.node) });
    }

    if (state.photoIndex === null || props.photoIndex === null) {
      updatedState.photoIndex = props.photoIndex;
    } else {
      updatedState.photoIndex = state.photoIndex;
    }
    return updatedState;
  }

  state = {
    photoIndex: null,
    sortedPhotos: null,
    selectedFilter: 'All',
  };

  renderImageCaption() {
    const displayPhotos = this.state.sortedPhotos[this.state.selectedFilter];
    return (
      <div className="d-flex flex-row">
        <div className="mr-1">
          {displayPhotos[this.state.photoIndex].room_name}
        </div>
        <div className="text-muted">
          ({this.state.photoIndex + 1} of {displayPhotos.length})
        </div>
      </div>
    )
  }

  renderImageTitle() {
    const lightboxPhotosArray = Object.keys(this.state.sortedPhotos).sort();
    if (lightboxPhotosArray.length > 1 ) {
      return (
        <div className="d-flex flex-row">
          {lightboxPhotosArray.map((roomOption) => {
            return (
              <div
                key={roomOption}
                className={`d-flex flex-row mr-2 PhotoLightbox-room-option-cursor`}
                onClick={() => this.setState({selectedFilter: roomOption, photoIndex: 0})}
              >
                {
                  this.state.selectedFilter === roomOption ?
                    <>
                      <div>
                        <strong>{roomOption}</strong>
                      </div>
                      <div className="ml-1 mr-2">
                        <strong>({this.state.sortedPhotos[roomOption].length})</strong>
                      </div>
                    </>
                    :
                    <>
                      <div className="text-muted">
                        {roomOption}
                      </div>
                      <div className="text-muted ml-1 mr-2">
                        ({this.state.sortedPhotos[roomOption].length})
                      </div>
                    </>
                }
              </div>
            )

          })}
        </div>
      )
    }
  }

  onClose() {
    this.setState({
      photoIndex: null,
      selectedFilter: 'All'
    });
    this.props.onCloseLightbox();
  }

  render() {
    const {sortedPhotos, photoIndex, selectedFilter} = this.state;
    const displayPhotos = sortedPhotos[selectedFilter];
    if (this.props.isLightboxOpen) {
      return (
        <Lightbox
          mainSrc={displayPhotos[photoIndex].photo_url}
          nextSrc={displayPhotos[(photoIndex + 1) % displayPhotos.length].photo_url}
          prevSrc={displayPhotos[(photoIndex + displayPhotos.length - 1) % displayPhotos.length].photo_url}
          onCloseRequest={() => this.onClose()}
          onMovePrevRequest={() =>
            this.setState({
              photoIndex: (photoIndex + displayPhotos.length - 1) % displayPhotos.length,
            })
          }
          onMoveNextRequest={() =>
            this.setState({
              photoIndex: (photoIndex + 1) % displayPhotos.length,
            })
          }
          enableZoom={false}
          imageTitle={this.renderImageTitle()}
          imageCaption={this.renderImageCaption()}
          imagePadding={50}
          reactModalStyle={{ overlay: { zIndex: 2500 }}}
          wrapperClassName="global-styleguide"
        />
      )
    } else {
      return null;
    }
  }
}

export default PhotoLightbox;
