import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import PieChart from "recharts/lib/chart/PieChart";
import {Pie, Cell, Label} from "recharts";
import ErrorBoundarySimple from "../ErrorBoundarySimple";
import { Component } from 'react';
import LoadingIcon from '../../Legacy/RsLoadingCircle'

const AGENT_CLIMATE_SCORE_QUERY = gql`
  query agentClimateScoreQuery($id: ID!){
    current_agent {
      id
      property_by_id(id: $id) {
        id
        raw_id
        encoded_id
        climate_score{
          target_url
          drought_risk{
            name
            score
            severity
          }
          storm_risk{
            name
            score
            severity
          }
          temp_risk{
            name
            score
            severity
          }
          fire_risk{
            name
            score
            severity
          }
          flood_risk{
            name
            score
            severity
          }
        }
      }
    }
  }
`;

const HOMEBUYER_CLIMATE_SCORE_QUERY = gql`
  query homebuyerClimateScoreQuery($id: ID!){
    current_user {
      id
      property_by_id(id: $id) {
        id
        raw_id
        encoded_id
        climate_score{
          target_url
          drought_risk{
            name
            score
            severity
          }
          storm_risk{
            name
            score
            severity
          }
          temp_risk{
            name
            score
            severity
          }
          fire_risk{
            name
            score
            severity
          }
          flood_risk{
            name
            score
            severity
          }
        }
      }
    }
  }
`;

class ClimateScore extends Component {
  colorFromScore(score) {
    if (score >= 0 && score <= 16) {
      return '#43A047';
    } else if (score >= 17 && score <= 33) {
      return '#7CB342';
    } else if (score >= 34 && score <= 50) {
      return '#FDD835';
    } else if (score >= 51 && score <= 67) {
      return '#FFA000';
    } else if (score >= 68 && score <= 84) {
      return '#E65100';
    } else if (score >= 85) {
      return '#B71C1C';
    }
  }

  renderCharts(climateScore) {
    const filteredKeys = Object.keys(climateScore).filter((k) => !(k === '__typename' || k === 'target_url'));
    return filteredKeys.map((key, idx) => {
      const name = climateScore[key].name;
      const score = climateScore[key].score;
      const severity = climateScore[key].severity;
      const color = this.colorFromScore(score);
      const colors = [color, color + '80'];
      const data = [{name, value: score}, {name: '', value: 100 - score}];

      return (
          <div key={name} className={`col-auto col-md-4 d-flex flex-column justify-content-center align-items-center text-center`}>
            <PieChart width={80} height={80}>
              <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={15} outerRadius={30}
                   paddingAngle={0}>
                {
                  data.map((entry, index) => {
                        return (
                            <Cell key={`cell-${index}`} fill={colors[index]}/>
                        )
                      }
                  )
                }
                <Label
                    value={score}
                    position="center"
                    fill="#333333"
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                >
                </Label>
              </Pie>
            </PieChart>
            <div style={{minWidth: 90}}>
              <p className="mb-0"><strong>{name}</strong></p>
              <p className="mb-0">{severity}</p>
            </div>
          </div>
      )
    })
  }

  unableToLoadMessage(){
    return(
        <div className="card-body">
          <div className="text-center">Sorry, we're unable to calculate ClimateCheck™ ratings for this listing.</div>
        </div>
    )
  }

  render() {
    return (
        <div className="card mb-4">
          <div className="card-header">Climate Risk Ratings</div>

          <div className="card-body pb-0">
            <div className="row justify-content-center">
              <div className="col">
                <div className="text-center">
                  <small>A 0-100 rating based on this home's future risk of climate-related hazards
                    compared to other homes in the United States.
                    <br/><a href="https://climatecheck.com/our-methodologies" target="_blank">Learn more at
                      ClimateCheck.com</a></small>
                </div>
              </div>
            </div>
          </div>
          <ErrorBoundarySimple>
            <Query
                query={this.props.agentView ? AGENT_CLIMATE_SCORE_QUERY : HOMEBUYER_CLIMATE_SCORE_QUERY}
                variables={{id: this.props.propertyId}}
            >
              {({loading, error, data}) => {
                if (loading) { return <LoadingIcon/> }
                if(!data){ return this.unableToLoadMessage() }

                const climateScoreData = this.props.agentView ?
                    data.current_agent.property_by_id.climate_score :
                    data.current_user.property_by_id.climate_score;

                if(!climateScoreData.fire_risk){ return this.unableToLoadMessage() }
                return (
                    <>
                      <div className="card-body">
                        <div className="row justify-content-center">
                          {this.renderCharts(climateScoreData)}
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="text-center">
                          <a
                              href={climateScoreData.target_url}
                              target="_blank"
                              className="btn btn-primary mx-auto"
                              role="button"
                              aria-disabled="true">
                            Get a Full Report
                          </a>
                        </div>
                      </div>
                    </>
                )
              }}
            </Query>
          </ErrorBoundarySimple>
        </div>
    )
  }
}

export default ClimateScore;
