import { FOR_RENT, FOR_SALE, SOLD, RENTED } from '../Common/Constants/PropertyConstants'

export const listingDaysOrSoldString = listing => {
  if ([FOR_SALE, FOR_RENT].includes(listing.simple_listing_status)) {
    return daysOnMarket(listing.days_on_site)
  }
  if ([SOLD, RENTED].includes(listing.simple_listing_status) && listing.sold_date) {
    return `${listing.simple_listing_status} on: ${listing.sold_date}`
  }
}

export const daysOnSite = (daysOnSite, simpleListingStatus) => {
  if ([FOR_SALE, FOR_RENT].includes(listing.simple_listing_status)) {
    return daysOnMarket(listing.days_on_site)
  }
}

export const daysOnMarket = num => {
  const daysNum = parseInt(num)
  if (isNaN(daysNum)) {
    return null
  }
  if (daysNum < 1) {
    return 'New on site'
  }
  return `${daysNum} days on site`
}
