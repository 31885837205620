import { Stack, Box } from '@mui/material'
import { useRef, useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Link as RouterLink } from 'react-router-dom'
import ListingCard, { CtaListingCard } from './ListingCard'
import { analytics } from '../Constants/AnalyticsDataAttributes'

const ListingCardsContainer = ({ listingsToDisplay, selectedListingId, previewMode, height, testId }) => {
  const listingCardRefs = useRef({})
  const theme = useTheme()
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'))
  const listingCardsStyles = isSmallerThanMd ? { overflowX: 'scroll', scrollBehavior: 'smooth', p: 2, maxWidth: '100%' } : { overflowY: 'scroll', maxHeight: height, scrollBehavior: 'smooth', minWidth: 'max-content', pl: 2, maxWidth: '276px' }
  const listingsContainerRef = useRef(null)

  const handleScrollTo = el => {
    if (isSmallerThanMd) {
      listingsContainerRef.current.scrollTo(el.offsetLeft - 20, 0)
    } else {
      listingsContainerRef.current.scrollTo(0, el.offsetTop - 20)
    }
  }

  useEffect(() => {
    if (selectedListingId) {
      const listingCardRef = listingCardRefs.current[selectedListingId]
      if (listingCardRef) {
        handleScrollTo(listingCardRef)
      }
    }
  }, [selectedListingId])

  return (
    <Stack ref={listingsContainerRef} spacing={2} direction={isSmallerThanMd ? 'row' : 'column'} sx={listingCardsStyles} id={`${testId}-listing-cards`}>
      {listingsToDisplay.map(listing => (
        <Box key={listing.id} ref={element => (listingCardRefs.current[listing.id] = element)}>
          <ListingCard listing={listing} isSelected={listing.id === selectedListingId} previewMode={previewMode} />
        </Box>
      ))}

      <RouterLink to={previewMode ? null : { pathname: '/map' }} data-rs-ph-event-name={analytics.homeReport.viewMoreListings}>
        <CtaListingCard previewMode={previewMode} />
      </RouterLink>
    </Stack>
  )
}

export default ListingCardsContainer
