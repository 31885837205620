import React from 'react'
import { Container, Grid, useMediaQuery } from '@mui/material'
import SeePhotosButton from './SeePhotosButton'
import PhotoCard from './PhotoCard'
import { useTheme } from '@mui/material/styles'

const SinglePhoto = ({ listingPhotos, onPhotoClick }) => (
  <Container sx={{ position: 'relative' }}>
    <Grid container sx={{ borderRadius: '16px', overflow: 'hidden' }}>
      <Grid item xs={12}>
        <PhotoCard
          image={listingPhotos[0]?.photo_url}
          height="600px"
          onClick={() => onPhotoClick(0)}
        />
      </Grid>
    </Grid>
    {listingPhotos.length > 1 && (
      <SeePhotosButton photoCount={listingPhotos.length} onClick={() => onPhotoClick(0)} />
    )}
  </Container>
)

/*
Two Photo Grid:
+--------------+--------------+
|              |              |
|   Photo 1    |   Photo 2    |
|              |              |
+--------------+--------------+
*/
const TwoPhotoGrid = ({ listingPhotos, onPhotoClick }) => (
  <Container sx={{ position: 'relative' }}>
    <Grid container sx={{ borderRadius: '16px', overflow: 'hidden' }}>
      <Grid item xs={6}>
        <PhotoCard
          image={listingPhotos[0].photo_url}
          height="500px"
          padding="0px 4px 0px 0px"
          onClick={() => onPhotoClick(0)}
        />
      </Grid>
      <Grid item xs={6}>
        <PhotoCard
          image={listingPhotos[1].photo_url}
          height="500px"
          onClick={() => onPhotoClick(1)}
        />
      </Grid>
    </Grid>
    <SeePhotosButton photoCount={listingPhotos.length} onClick={() => onPhotoClick(0)} />
  </Container>
)

/*
Multiple Photo Grid:
+---------------------------------------------------+
|                     | +----------+   +----------+ |
|                     | |  Photo 2  | |  Photo 3  | |
|                     | +----------+   +----------+ |
|      Photo 1        |                             |
|                     | +----------+   +----------+ |
|                     | |  Photo 4  | |  Photo 5  | |
|                     | +----------+   +----------+ |
+---------------------------------------------------+
*/
const MultiplePhotoGrid = ({ listingPhotos, onPhotoClick }) => (
  <Container sx={{ position: 'relative' }}>
    <Grid container sx={{ borderRadius: '16px', overflow: 'hidden' }}>
      <Grid item xs={6}>
        <PhotoCard
          image={listingPhotos[0].photo_url}
          height="500px"
          padding="0px 10px 0px 0px"
          onClick={() => onPhotoClick(0)}
        />
      </Grid>
      <Grid item xs={6} container>
        {listingPhotos.slice(1, 5).map((photo, index) => {
          const paddingValues = [
            '0px 10px 10px 0px', // Photo 2 (Top-left in square grid)
            '0px 0px 10px 0px', // Photo 3 (Top-right in square grid)
            '0px 10px 0px 0px', // Photo 4 (Bottom-right in square grid)
          ]
          const padding = paddingValues[index] || '0px' // Photo 5 (Bottom-right) gets no padding
          return (
            <Grid item xs={6} key={photo.photo_url}>
              <PhotoCard
                image={photo.photo_url}
                height="250px"
                padding={padding}
                onClick={() => onPhotoClick(index + 1)}
              />
            </Grid>
          )
        })}
      </Grid>
    </Grid>
    <SeePhotosButton photoCount={listingPhotos.length} onClick={() => onPhotoClick(0)} />
  </Container>
)

const PhotoGrid = ({ photos, onPhotoClick }) => {
  const listingPhotos = photos.edges.map(photo => photo.node) || []
  const photoCount = listingPhotos.length
  const theme = useTheme()
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'))

  if (photoCount < 2 || isSmallerThanMd) {
    return <SinglePhoto listingPhotos={listingPhotos} onPhotoClick={onPhotoClick} />
  }
  if (photoCount < 5) {
    return <TwoPhotoGrid listingPhotos={listingPhotos} onPhotoClick={onPhotoClick} />
  }
  return <MultiplePhotoGrid listingPhotos={listingPhotos} onPhotoClick={onPhotoClick} />
}

export default PhotoGrid
