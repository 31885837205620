import { useSnackbar } from 'notistack'
import { gql, useMutation } from '@apollo/client'
import { InputLabel, MenuItem, Select, FormControl } from '@mui/material'
import {AGENT_SCHEDULE_OPTIONS, CLIENT_SCHEDULE_OPTIONS} from "../Constants/ListingAlertConstants";

const UPDATE_SAVED_SEARCH_MUTATION = gql`
  mutation updateSavedSearch($input: UpdateSavedSearchInput!) {
    update_saved_search(input: $input) {
      saved_search {
        id
        schedule
        is_active
      }
      errors {
        key
        message
      }
    }
  }
`

const ListingAlertScheduleSelect = ({ listingAlert, isClientFacing, label }) => {
  const { enqueueSnackbar } = useSnackbar()

  const options = isClientFacing ? CLIENT_SCHEDULE_OPTIONS : AGENT_SCHEDULE_OPTIONS;

  const [updateSavedSearch, { loading }] = useMutation(UPDATE_SAVED_SEARCH_MUTATION, {
    onError: () =>
      enqueueSnackbar('Error: Unable to update listing alert.', {
        variant: 'error',
        autoHideDuration: 3000,
      }),
    onCompleted: data =>
      data.update_saved_search && data.update_saved_search.errors
        ? enqueueSnackbar('Error: Unable to update listing alert.', {
            variant: 'error',
            autoHideDuration: 3000,
          })
        : enqueueSnackbar('Listing Alert Updated', {
            variant: 'success',
            autoHideDuration: 3000,
          }),
  })

  return (
    <FormControl fullWidth>
      {label && <InputLabel id="listing-alert-schedule-select-label">{label}</InputLabel>}
      <Select
        id="listing-alert-schedule-select"
        displayEmpty
        label={label}
        value={listingAlert.schedule}
        size="small"
        fullWidth
        disabled={loading}
        onChange={e =>
          updateSavedSearch({
            variables: {
              input: {
                id: listingAlert.id,
                schedule: e.target.value,
              },
            },
          })
        }
      >
        {options.map(opt => (
          <MenuItem key={opt.value} value={opt.value}>
            {opt.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default ListingAlertScheduleSelect
