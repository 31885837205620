//DO NOT USE GOING FORWARD. USE STORE.JS (store)
import _ from 'lodash';
import moment from 'moment';

export default {
  setItem: function (k, v, expirationDate) {
    const val = JSON.stringify(v);
    localStorage.setItem(k, val);
  },
  getItem: function (k) {
    return JSON.parse(localStorage.getItem(k));
  },
  getOrInitItem: function (k, defaultItem, expirationDate) {
    let item = this.getItem(k);
    if (item === null && defaultItem) {
      this.setItem(k, defaultItem);

      if (!!expirationDate && moment(expirationDate).isValid()) {
        const expirationDateKey = `${k}__expiration_date`;
        localStorage.setItem(expirationDateKey, JSON.stringify(expirationDate));
      }
    }
    return item !== null ? item : defaultItem;
  },
  removeItem: function (k) {
    localStorage.removeItem(k)
  },
  pushItem: function (k, v) {
    let item = this.getItem(k);
    if (Array.isArray(item)) {
      item.push(v);
      this.setItem(k, item);
    }
  },
  clearExpiredItems: function () {
    if (Object.keys(localStorage).length > 0) {
      let expKeys = Object.keys(localStorage).filter((key) => {
        return _.endsWith(key, '__expiration_date');
      });

      expKeys.forEach((k) => {
        let expDate = this.getItem(k);

        if (moment().isAfter(expDate)) {
          let objectKey = k.replace('__expiration_date', '');

          this.removeItem(k);
          this.removeItem(objectKey);
        }
      })
    }
  },
  checkForLocalStorage: function (callbackFn) {
    if (typeof localStorage === 'object') {
      try {
        callbackFn();
      } catch (e) {}
    }
  }
};


/*
If you want to expire a localStorage object, use the following syntax:
key name of object you want to expire:
  agent-live_feed-filter_settings
key name of the expiration date field:
 agent-live_feed-filter_settings__expiration_date:
*/
