const MONTHLY = 'MONTHLY';
const QUARTERLY = 'QUARTERLY';
const BIANNUALLY = 'BIANNUALLY';
const ANNUALLY = 'ANNUALLY';
const MANUAL = 'MANUAL';
const AGENT_SCHEDULE_OPTIONS = [
  {label: 'Monthly', value: MONTHLY},
  {label: 'Quarterly', value: QUARTERLY},
  {label: 'Biannually', value: BIANNUALLY},
  {label: 'Annually', value: ANNUALLY},
  {label: 'Manual', value: MANUAL},
];

const CLIENT_SCHEDULE_OPTIONS = [
  {label: 'Monthly', value: MONTHLY},
  {label: 'Quarterly', value: QUARTERLY},
  {label: 'Biannually', value: BIANNUALLY},
  {label: 'Annually', value: ANNUALLY},
  {label: 'Disabled', value: MANUAL},
];

const HALF_MILE = 'HALF_MILE'
const ONE_MILE = 'ONE_MILE'
const ES_LOCATION = 'ES_LOCATION'
const LOCATION_OPTIONS = [
  {label: '0.5 Mile Radius', value: HALF_MILE},
  {label: 'One Mile Radius', value: ONE_MILE},
  {label: 'Zipcode', value: ES_LOCATION}
]
const DAYS_OPTIONS = [
  {label: 'One day after alert creation', value: 1},
  {label: 'One week after alert creation', value: 7},
  {label: 'One month after alert creation', value: 30}
]

export {
  MONTHLY,
  QUARTERLY,
  BIANNUALLY,
  ANNUALLY,
  MANUAL,
  CLIENT_SCHEDULE_OPTIONS,
  AGENT_SCHEDULE_OPTIONS,
  HALF_MILE,
  ONE_MILE,
  ES_LOCATION,
  LOCATION_OPTIONS,
  DAYS_OPTIONS,
}