import { withApollo } from '@apollo/client/react/hoc'

import Textarea from 'react-textarea-autosize'
import { gql } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import remove from 'lodash/remove'
import { toast } from 'react-toastify'
import UnsentAttachedListing from './UnsentAttachedListing'
import { Component, Fragment } from 'react';
import { AnalyticsClass } from '../AnalyticClasses'

const CREATE_CONVERSATION_MESSAGE = gql`
  mutation createConversationMessage($data: CreateConversationMessageMutationInput!) {
    create_conversation_message(input: $data) {
      conversation {
        id
        messages(first: 10) @connection(key: "messages") {
          unread_count
          edges {
            cursor
            node {
              body
              attachments(first: 2) @connection(key: "attachments") {
                total_count
                edges {
                  node {
                    ... on Property {
                      id
                      baths_total
                      beds_total
                      structure_sqft
                      address
                      city
                      state
                      postal_code
                      display_price
                      slug
                      photos(first: 1) {
                        edges {
                          node {
                            id
                            photo_url
                          }
                        }
                      }
                    }
                    ... on PremarketListing {
                      id
                    }
                    ... on ReverseProspectRequest {
                      id
                      state
                      property {
                        id
                        address
                        photos(first: 1) {
                          edges {
                            node {
                              id
                              photo_url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              id
              created_at
              sender: sender_participant {
                id: participant_id
                full_name
              }
            }
          }
        }
        selectorMessages: messages(first: 1) @connection(key: "selectorMessages") {
          unread_count
          edges {
            node {
              id
              body
              created_at
              attachments {
                total_count
              }
              sender: sender_participant {
                id: participant_id
                full_name
              }
            }
          }
        }
      }
      errors {
        key
        message
      }
    }
  }
`

class ConversationMessageInput extends Component {
  state = {
    messageBody: '',
    attachedListings: [],
  }

  componentDidMount() {
    if (this.props.location && this.props.location.search.includes('attachments')) {
      const listingId = this.props.location.search
        .split('&')
        .find(el => el.includes('attachments'))
        .split('=')[1]

      this.props.client
        .query({
          query: gql`
            query agentAttachListingsFromParams($id: ID!) {
              current_agent {
                id
                property_by_id(id: $id) {
                  id
                  encoded_id
                  address
                  city
                  mls_number
                  slug
                  simple_listing_status
                  photos(first: 1) {
                    edges {
                      node {
                        id
                        photo_url
                      }
                    }
                  }
                }
              }
            }
          `,
          variables: { id: listingId },
        })
        .then(({ data }) => {
          const listing = data.current_agent.property_by_id
          this.attachListing(listing)
        })
        .catch(e => {
          console.log(e)
        })
    }
  }

  attachListing = listingToAttach => {
    const { attachedListings } = this.state
    if (attachedListings.findIndex(listing => listing.id === listingToAttach.id) !== -1) {
      toast.warn('Listing already attached to current message', {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    } else {
      attachedListings.push(listingToAttach)
      this.setState({ attachedListings })
    }
  }

  removeListing = listingToRemove => {
    const { attachedListings } = this.state
    remove(attachedListings, listing => listing.id === listingToRemove.id)
    this.setState({ attachedListings })
  }

  renderSendButton() {
    const refetchQueries = []
    if (this.props.refetchQuery) {
      refetchQueries.push({
        query: this.props.refetchQuery,
        variables: this.props.refetchVariables,
      })
    }
    return (
      <Mutation
        mutation={CREATE_CONVERSATION_MESSAGE}
        refetchQueries={() => refetchQueries}
        onError={() =>
          toast.error('Something went wrong, please try again', {
            position: toast.POSITION.TOP_RIGHT,
          })
        }
        onCompleted={data => {
          if (this.props.sendMessageCallback) {
            const conversationId = data.create_conversation_message.conversation.id
            this.props.sendMessageCallback(conversationId)
          }
          this.setState({ messageBody: '', attachedListings: [] })
        }}
      >
        {(createConversationMessage, { data, loading }) => {
          const sendEnabled =
            !!this.props.participantId &&
            (!!this.state.messageBody || this.state.attachedListings.length > 0)
          let message = {
            body: this.state.messageBody,
            recipient: this.props.participantId,
          }
          if (this.state.attachedListings.length) {
            const attachment_ids = this.state.attachedListings.reduce((accum, currentListing) => {
              accum.push(currentListing.encoded_id)
              return accum
            }, [])
            message = { ...message, attachment_ids }
          }
          return (
            <button
              className={`btn btn-primary ml-2 ${AnalyticsClass('send-conversation-message')}`}
              id="send-message-button"
              onClick={() =>
                createConversationMessage({
                  variables: {
                    data: message,
                  },
                })
              }
              disabled={!sendEnabled || loading}
            >
              {loading ? 'Sending...' : 'Send Message'}
            </button>
          )
        }}
      </Mutation>
    )
  }

  render() {
    return (
      <div className="d-flex flex-column">
        <div className="d-flex flex-row justify-content-end align-items-center py-2 px-4">
          <Textarea
            id="sendMessage"
            type="text"
            autoCapitalize="sentences"
            aria-label="Send a message"
            autoCorrect="on"
            autoComplete="off"
            wrap="hard"
            minRows={1}
            maxRows={4}
            className="w-100 border-light rounded"
            placeholder="Send a message"
            value={this.state.messageBody}
            style={{ minHeight: '50px' }}
            onChange={e => this.setState({ messageBody: e.target.value })}
          />
        </div>

        <div className="d-flex flex-row justify-content-end align-items-center py-2 px-4 pb-0">
          <div className="d-flex flex-row justify-content-start align-items-center flex-wrap">
            {this.state.attachedListings.length === 0 && (
              <div>
                <small>No Listings Attached</small>
              </div>
            )}
            {this.state.attachedListings.length > 0 &&
              this.state.attachedListings.map(attachedListing => (
                <Fragment key={attachedListing.id}>
                  <UnsentAttachedListing
                    attachedListing={attachedListing}
                    removeListing={this.removeListing}
                  />
                </Fragment>
              ))}
          </div>
        </div>

        <div className="d-flex flex-row justify-content-end align-items-center py-2 px-4">
          {this.props.children({
            attachListing: listing => this.attachListing(listing),
            removeListing: listing => this.removeListing(listing),
            attachedListings: this.state.attachedListings,
          })}

          {this.renderSendButton()}
        </div>
      </div>
    )
  }
}

export default withApollo(ConversationMessageInput)
