import React from 'react'
import { Box, Typography } from '@mui/material'
import PhotoCircle from './PhotoCircle'

const ShowingRequestAttachment = ({ propertyAddress, listingPhotoUrl }) => {
  return (
    <div className="d-flex flex-row w-100 justify-content-center align-items-center text-dark">
      <PhotoCircle photoUrl={listingPhotoUrl} additionalClasses={'mr-3'} />
      <Box ml={2}>
        <Typography variant="body1">Showing Requested for</Typography>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          {propertyAddress}
        </Typography>
      </Box>
    </div>
  )
}

const ShowingRequestLinkedAttachment = ({
  listingId,
  listingSlug,
  propertyAddress,
  listingPhotoUrl,
  renderListingLink,
}) => {
  const linkingParams = {
    listingId: listingId, //Required by Agent app for linking to listings
    listingSlug: listingSlug, //Required by Homebuyer app for linking to listings
    renderAttachment: () => <ShowingRequestAttachment {...{ propertyAddress, listingPhotoUrl }} />,
  }
  return renderListingLink(linkingParams)
}

export default ShowingRequestLinkedAttachment
