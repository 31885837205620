export const DAILY = 'DAILY'
export const ASAP = 'ASAP'
export const MONTHLY = 'MONTHLY'
export const MANUAL_DAILY = 'MANUAL_DAILY'
export const MANUAL = 'MANUAL'
export const NEVER = 'NEVER'

export const CLIENT_SCHEDULE_OPTIONS = [
  { value: DAILY, label: 'Daily' },
  { value: ASAP, label: 'ASAP' },
  { value: MONTHLY, label: 'Monthly' },
  { value: NEVER, label: 'Disabled' },
]

export const AGENT_SCHEDULE_OPTIONS = [
  { value: DAILY, label: 'Daily' },
  { value: ASAP, label: 'ASAP' },
  { value: MONTHLY, label: 'Monthly' },
  { value: MANUAL_DAILY, label: 'Handpicked (Daily)' },
  { value: MANUAL, label: 'Handpicked (ASAP)' },
  { value: NEVER, label: 'Disabled' },
]

export const DEFAULT_SIMPLE_PROPERTY_TYPES = ['CONDO', 'CONDOP', 'COOP', 'SFR', 'TC', 'TOWNHOUSE']
