export const BIWEEKLY = 'BIWEEKLY'
const MONTHLY = 'MONTHLY'
const QUARTERLY = 'QUARTERLY'
export const MANUAL = 'MANUAL'

export const AGENT_SCHEDULE_OPTIONS = [
  { label: 'Bi-Weekly', value: BIWEEKLY },
  { label: 'Monthly', value: MONTHLY },
  { label: 'Quarterly', value: QUARTERLY },
  { label: 'Manual', value: MANUAL },
]

export const CLIENT_SCHEDULE_OPTIONS = [
  { label: 'Bi-Weekly', value: BIWEEKLY },
  { label: 'Monthly', value: MONTHLY },
  { label: 'Quarterly', value: QUARTERLY },
  { label: 'Disabled', value: MANUAL },
]
