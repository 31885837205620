import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import PhotoCircle from './PhotoCircle'
import { IoIosArrowForward } from 'react-icons/io'
import { TabletAndDesktop } from '../ResponsiveComponents'

class AttachedListings extends Component {
  renderAdditionalListingsButton(filteredAttachments) {
    const attachmentPath =
      this.props.match.path === '/:id'
        ? `/${this.props.match.params.id}/attachments/${this.props.messageId}`
        : `/conversations/attachments/${this.props.messageId}`
    return (
      <Link
        to={{ pathname: attachmentPath }}
        className={`d-flex flex-row w-100 justify-content-center align-items-center my-1 view-attachments-button text-dark`}
      >
        {filteredAttachments[0] && this.renderListingPhoto(filteredAttachments[0].node)}
        {filteredAttachments[1] &&
          this.renderListingPhoto(
            filteredAttachments[1].node,
            'AttachedListings-secondaryPhotoCircle'
          )}
        View {this.props.totalCount} attached listings
        <TabletAndDesktop>
          <IoIosArrowForward style={{ fontSize: 20 }} />
        </TabletAndDesktop>
      </Link>
    )
  }

  renderListingPhoto(attachment, additionalClasses = '') {
    const photo = attachment.photos.edges[0].node
    return <PhotoCircle photoUrl={photo.photo_url} additionalClasses={additionalClasses} />
  }

  renderSingleAttachment = filteredAttachments => {
    const attachment = filteredAttachments[0].node
    const linkingParams = {
      listingId: attachment.id, //Required by Agent app for linking to listings
      listingSlug: attachment.slug, //Required by Homebuyer app for linking to listings
      renderAttachment: () => (
        <div className="d-flex flex-row w-100 justify-content-center align-items-center text-dark">
          {this.renderListingPhoto(attachment, 'mr-3')}
          {attachment.address}
        </div>
      ),
    }
    return this.props.renderListingLink(linkingParams)
  }

  render() {
    // NOTE: Update this once null property change implemented
    const filteredAttachments = this.props.attachments.filter(attachment => !!attachment.node)
    if (filteredAttachments.length > 0) {
      return (
        <>
          {filteredAttachments.length > 1
            ? this.renderAdditionalListingsButton(filteredAttachments)
            : this.renderSingleAttachment(filteredAttachments)}
        </>
      )
    } else {
      return (
        <div
          className="d-flex flex-row w-100 justify-content-center align-items-center text-dark"
          style={{ fontStyle: 'italic' }}
        >
          {this.props.attachments.length > 1
            ? 'These attachments are no longer viewable'
            : 'This attachment is no longer viewable'}
        </div>
      )
    }
  }
}

export default withRouter(AttachedListings)
