import LazyLoad from 'react-lazyload'
import { FeatureGroup, LayersControl, Map, Marker, TileLayer, GeoJSON } from 'react-leaflet'
import { divIcon } from 'leaflet'
import { useRef, useState, useCallback } from 'react'
import { Card } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { MAP_ATTRIBUTION, SATELLITE_TILE_URL, TILE_URL } from '../../Utils/MapUtils'
import { shortPrice } from '../../Utils/NumberUtils'

const AreaReportMap = ({ marketGeoJson, listingsToDisplay, selectedListingId, setSelectedListingId, previewMode }) => {
  const map = useRef(null)

  const listingPinsRef = useCallback(node => {
    // Fits listing pins in viewport
    if (node !== null) {
      const leafletMap = map.current.leafletElement
      const group = node.leafletElement
      if (listingsToDisplay.length === 1) {
        leafletMap.setView([listingsToDisplay[0].latitude, listingsToDisplay[0].longitude], 15)
      } else if (listingsToDisplay.length > 1) {
        leafletMap.fitBounds(group.getBounds())
      }
    }
  }, [])

  const theme = useTheme()
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'))

  const [clickedPinListingId, setClickedPinListingId] = useState(null)

  const makeMarkers = listings =>
    listings.map(listing => (
      <Marker
        key={listing.id}
        position={[listing.latitude, listing.longitude]}
        zIndexOffset={selectedListingId === listing.id ? 1000 : 0}
        icon={divIcon({
          type: 'div',
          className: `home-report-listing-marker ${selectedListingId === listing.id ? 'selected-home-report-listing-marker' : ''}`,
          popupAnchor: [2, -25],
          iconSize: [64, 20],
          iconAnchor: [32, 20],
          html: `<i style="font-weight: bold">${shortPrice(listing.display_price, 0)}</i>`,
        })}
        onClick={() => {
          setClickedPinListingId(listing.id)
          setSelectedListingId(listing.id)
        }}
        onMouseOver={() => {
          setSelectedListingId(listing.id)
        }}
        onMouseOut={() => {
          if (clickedPinListingId !== listing.id) {
            setSelectedListingId(null)
          }
        }}
      />
    ))

  return (
    <Card variant="outlined">
      <LazyLoad height={isSmallerThanMd || previewMode ? 350 : 450}>
        <Map ref={map} center={[0, 0]} style={isSmallerThanMd || previewMode ? { height: 350, width: '100%', borderRadius: 10 } : { height: 450, width: '100%', borderTopLeftRadius: 10, borderTopRightRadius: 10 }} scrollWheelZoom={false}>
          <LayersControl position="topright">
            <LayersControl.BaseLayer name="Streets" checked>
              <TileLayer attribution={MAP_ATTRIBUTION} url={TILE_URL} />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Satellite">
              <TileLayer attribution={MAP_ATTRIBUTION} url={SATELLITE_TILE_URL} />
            </LayersControl.BaseLayer>
          </LayersControl>
          <FeatureGroup ref={listingPinsRef}>{makeMarkers(listingsToDisplay)}</FeatureGroup>
          <GeoJSON
            data={marketGeoJson}
            style={() => ({
              color: '#575757',
              weight: 2,
              fillOpacity: 0,
            })}
          />
        </Map>
      </LazyLoad>
    </Card>
  )
}

export default AreaReportMap
