import { useState, useEffect, useRef } from 'react'
import { Box, CardContent, Divider, Stack, Typography } from '@mui/material'
import { gql } from '@apollo/client'
import HomeIcon from '@mui/icons-material/Home'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import AreaReportMap from './AreaReportMap'
import ListingCardsContainer from './ListingCardsContainer'
import ListingsMetrics from './ListingsMetrics'
import { useVerticalScrollWithShadow, useHorizontalScrollWithShadow } from '../../CustomHooks/useScrollWithShadowHook'
import RsCard from '../UIComponents/Card/RsCard'
import { LISTING_ATTRIBUTES_FRAGMENT } from './ListingCard'

export const LISTINGS_FRAGMENT = gql`
  fragment Listings on MarketActivityAnalytics {
    top_viewed_listings {
      listing {
        ...ListingAttributes
      }
    }
    recently_sold_listings {
      ...ListingAttributes
    }
    recently_updated_listings {
      ...ListingAttributes
    }
  }
  ${LISTING_ATTRIBUTES_FRAGMENT}
`

const Listings = ({ title, testId, metrics = [], marketGeoJson, listings, previewMode, loading }) => {
  const [height, setHeight] = useState(0)
  const [selectedListingId, setSelectedListingId] = useState(null)
  const { verticalBoxShadow, onVerticalScrollHandler } = useVerticalScrollWithShadow()
  const { horizontalBoxShadow, onHorizontalScrollHandler } = useHorizontalScrollWithShadow()
  const mapContentRef = useRef(null)
  const theme = useTheme()
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'))
  const isSmallerScreen = useMediaQuery('(max-width: 1080px)') // set to avoid only one metrics wrapping to the next line
  const metricStackStyles = { mb: 2, px: isSmallerScreen ? 2 : 6, maxWidth: isSmallerScreen ? '1280px' : '100%' }
  const divider = <Divider orientation="vertical" />

  useEffect(() => {
    if (mapContentRef.current) {
      setHeight(mapContentRef.current.clientHeight)
    }
  })

  if (listings.length === 0) {
    return (
      <RsCard title={title}>
        <CardContent sx={{ textAlign: 'center' }}>
          <HomeIcon fontSize="large" sx={{ color: theme.palette.grey[400] }} />
          <Typography sx={{ color: theme.palette.grey[400] }}>No listings available</Typography>
        </CardContent>
      </RsCard>
    )
  }

  // Filter out the metrics which are 0
  const filteredMetrics = metrics.filter(metric => metric.value !== 0)

  return (
    <RsCard title={title}>
      <CardContent id={`${testId}-content`}>
        {filteredMetrics.length > 0 && (
          <Box sx={{ width: '100%' }}>
            <Stack direction="row" justifyContent="space-around" flexWrap="wrap" sx={metricStackStyles} divider={divider} spacing={1}>
              {filteredMetrics.map(({ key, title, value }) => (
                <Box key={key}>
                  <ListingsMetrics title={title} value={value} loading={loading} />
                </Box>
              ))}
            </Stack>
          </Box>
        )}
        <Stack spacing={isSmallerThanMd ? 0 : 2} direction={isSmallerThanMd ? 'column' : 'row'}>
          <Box sx={{ width: '100%' }} ref={mapContentRef}>
            <AreaReportMap listingsToDisplay={listings} selectedListingId={selectedListingId} setSelectedListingId={setSelectedListingId} previewMode={previewMode} marketGeoJson={marketGeoJson} />
            <Typography variant="subtitle2" component="p" pt={2} textAlign={isSmallerThanMd ? 'center' : 'left'} sx={{ maxWidth: 'fit-content' }}>
              For MLS compliance purposes, a maximum of 100 listings are shown.
            </Typography>
          </Box>
          <Box flexShrink={1} sx={{ position: 'relative', mt: isSmallerThanMd ? 2 : 0 }} onScroll={isSmallerThanMd ? onHorizontalScrollHandler : onVerticalScrollHandler}>
            <Box sx={{ position: 'absolute', top: 0, bottom: 0, height: '100%', width: '100%', borderRadius: '5px', boxShadow: isSmallerThanMd ? horizontalBoxShadow : verticalBoxShadow, pointerEvents: 'none', zIndex: 1 }} />
            <ListingCardsContainer listingsToDisplay={listings} selectedListingId={selectedListingId} previewMode={previewMode} height={height} testId={testId} />
          </Box>
        </Stack>
      </CardContent>
    </RsCard>
  )
}

export default Listings
