import { Skeleton } from '@mui/material'

const RsSkeletonLoading = (props) => {
  return (
    <Skeleton
      {...props}
      sx={theme => ({
        borderRadius: theme.general.borderRadius,
      })}
      variant="rectangle"
    />
  )
}

export default RsSkeletonLoading;