import React from 'react'
import { Card, CardMedia } from '@mui/material'

const PhotoCard = ({ image, height, padding, onClick }) => {
  return (
    <Card sx={{ borderRadius: 0, boxShadow: 'none' }} onClick={onClick}>
      <CardMedia component="img" style={{ height, objectFit: 'cover', padding }} image={image} />
    </Card>
  )
}

export default PhotoCard
