import {
  IoIosArrowRoundBack,
  IoMdClose
} from "react-icons/io";
import PerfectScrollbar from 'react-perfect-scrollbar';
import {Link, withRouter, matchPath} from "react-router-dom";
import { Component, Fragment } from 'react';
import TimeFormatter from '../../Utils/TimeUtils';
import ClassNames from 'classnames';
import { MobileAndTablet } from '../ResponsiveComponents'

class AttachmentsSidebar extends Component {

  _containerClassName(matchesMobile) {
    return ClassNames("d-flex flex-column", {
      'col-12': matchesMobile,
      'h-100 border-light border-left': !matchesMobile,
    });
  };

  render() {
    const {message, attachments, isMessageSentByCurrent} = this.props;

    const backPath = this.props.match.path === '/:id/attachments/:messageId' ?
        `/${this.props.match.params.id}` :
        '/conversations';

    return (
        <MobileAndTablet>
          {(matches) => {

            return(
                <div className={this._containerClassName(matches)} id="attachments-sidebar">
                  <div className="d-flex flex-row justify-content-between align-items-center pl-3 py-4 border-light border-bottom">
                    {matches &&
                        <Link
                            to={{pathname: backPath}}
                            className="border-light border-right d-flex flex-row justify-content-center align-items-center text-dark px-2"
                        >
                          <IoIosArrowRoundBack
                              size={20}
                          />
                          Back
                        </Link>
                    }

                    <div className="d-flex flex-column">
                      <strong>Listings sent by {isMessageSentByCurrent ? 'you' : message.sender.full_name}</strong>
                      {TimeFormatter.formatUnix(message.created_at, 'monthDayAtTime')}
                    </div>

                    {
                      !matches &&
                      <Link
                          to={{pathname: backPath}}
                          className="btn icon-btn btn-outline-dark borderless"
                      >
                        <IoMdClose/>
                      </Link>
                    }
                  </div>
                  <div className="d-flex flex-column">
                    <PerfectScrollbar
                        className="w-100 px-2 AttachmentsSidebar-scroll-height"
                    >
                      {attachments.map(attachment => {
                        return (
                            <Fragment key={attachment.node.slug}>
                              {this.props.renderAttachment(attachment.node, isMessageSentByCurrent)}
                              <hr className="border-light m-0"/>

                            </Fragment>
                        )
                      })}
                    </PerfectScrollbar>
                  </div>
                </div>
            )
          }}
        </MobileAndTablet>
    )
  }
}


const AttachmentsSidebarWithRouter = withRouter(AttachmentsSidebar);

export default AttachmentsSidebarWithRouter;
