import { Popper } from '@mui/material'

const BottomPopper = props => {
  const modifiers = [
    {
      name: 'flip',
      options: {
        fallbackPlacements: [],
      },
    },
  ]

  return (
    <Popper
      {...props}
      modifiers={modifiers}
      popperOptions={{
        placement: 'bottom',
      }}
    />
  )
}

export default BottomPopper
