import { Component } from 'react';
import TimeFormatter from '../../Utils/TimeUtils';
class ListingFooter extends Component {

  _propertyUpdatedAt = (updatedAt) => {
    return updatedAt ? TimeFormatter.formatUnix(updatedAt, 'monthDayYearTime') : 'unknown';
  };

  render() {
    const {
      mls_number,
      mls_display_name,
      updated_at,
      feed_updated_at,
      mls_disclaimer,
      mls_logo,
      listing_agent_attribution,
      co_listing_agent_attribution,
      selling_agent_attribution,
      co_selling_agent_attribution
    } = this.props.listing;

    return (
      <nav className="footer bg-white text-dark">
      <div className="container-fluid">
        <div className="row pt-3">
          <div className="col">
            <h5 >Listing Attribution</h5>
          </div>
        </div>
      </div>
          <div className="container-fluid">
          <div className="row">
            <div className="col">

              {listing_agent_attribution && <p className="mls-disclaimer mb-1">
                <strong>Listing Offered By:</strong>&nbsp;
                {listing_agent_attribution}
              </p>}
              {co_listing_agent_attribution && <p className="mls-disclaimer mb-1">
                <strong>Co-Listed By:</strong>&nbsp;
                {co_listing_agent_attribution}
              </p>}
              {selling_agent_attribution && <p className="mls-disclaimer mb-1">
                <strong>Selling Agent/Office:</strong>&nbsp;
                {selling_agent_attribution}
              </p>}
              {co_selling_agent_attribution && <p className="mls-disclaimer mb-1">
                <strong>Co-Selling Agent/Office:</strong>&nbsp;
                {co_selling_agent_attribution}
              </p>}

              <p>
                <strong>Listing ID:</strong>&nbsp;
                <span id="mls-number">{mls_number}</span>
              </p>

              {mls_logo && <div className="mls-disclaimer-image">
                <img  alt={`Logo for ${mls_display_name}`} src={mls_logo} style={{maxHeight: 120}}/>
              </div>
              }
              <p id="mls-disclaimer" className="mls-disclaimer mt-3" dangerouslySetInnerHTML={{ __html: mls_disclaimer }} />

              <p>
                {mls_display_name} content last updated on {TimeFormatter.formatUnix(feed_updated_at, 'monthDayYearTime')}. Some properties which appear for sale on this web site may subsequently have sold or may no longer be available.
              </p>
              <p>
                Listing last updated: {this._propertyUpdatedAt(updated_at)}.
              </p>
              <img src="//s3.amazonaws.com/RealScoutStatic/MLSLogos/recolorado_equal_housing.jpg" alt="Equal Housing Logo" style={{width: 40}}/>
            </div>
          </div>
          {this.props.children && this.props.children}
        </div>
      </nav>
    );
  }
}

export default ListingFooter;
