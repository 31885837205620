import {Box, alpha} from '@mui/material';

const PageHeader = ({children}) => {
  return (
    <Box
      display="flex"
      alignItems={{xs: 'stretch', md: 'center'}}
      flexDirection={{xs: 'column', md: 'row'}}
      justifyContent="space-between"
      sx={(theme) => ({
        paddingX: theme.spacing(4),
        paddingY: theme.spacing(3),
        background: theme.colors.alpha.white[100],
        marginBottom: `${theme.spacing(4)}`,
        boxShadow: `0px 2px 4px -3px ${alpha(theme.colors.alpha.black[100], 0.8)},
                    0px 5px 12px -4px ${alpha(theme.colors.alpha.black[100], 0.05)}`
        })}
    >
      {children}
    </Box>
  );
}

export default PageHeader;
