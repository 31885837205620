import PerfectScrollbar from 'react-perfect-scrollbar'
import reverse from 'lodash/reverse'
import slice from 'lodash/slice'
import { gql } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import { Box } from '@mui/material'
import AttachedListings from './AttachedListings'
import TimeFormatter from '../../Utils/TimeUtils'
import ReverseProspectRequestAttachment from '../../Agent/Pages/Conversations/ReverseProspectRequestAttachment'
import HomeReportAttachment from './HomeReportAttachment'
import MarketAlertAttachment from './MarketAlertAttachment'
import ShowingRequestAttachment from './ShowingRequestAttachment'
import { Component, Fragment } from 'react'

class ConversationMessages extends Component {
  constructor(props) {
    super(props)
    this._scrollBarRef = null
  }

  componentDidMount() {
    // Since componentDidUpdate is not called on initial mount
    if (this.props.messages.length > 0) {
      if (this.props.messages[0].cursor === this.props.startCursor) {
        this._scrollBarRef._container.scrollTop = this._scrollBarRef._ps.contentHeight
      } else {
        this._scrollBarRef._container.scrollTop = 0
      }

      this.props.client
        .mutate({
          mutation: gql`
            mutation markConversationAsSeen($input: MarkConversationAsSeenInput!) {
              mark_conversation_as_seen(input: $input) {
                conversation_message {
                  id
                  conversation {
                    id
                    messages {
                      unread_count
                    }
                  }
                }
              }
            }
          `,
          variables: { input: { conversation_message_id: this.props.messages[0].node.id } },
        })
        .then(({ data }) => {})
        .catch(e => {
          console.log(e)
        })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.messages.length > 0 && prevProps.messages.length > 0) {
      if (
        this.props.participant.id !== prevProps.participant.id ||
        this.props.messages[0].node.id !== prevProps.messages[0].node.id
      ) {
        this._scrollBarRef._container.scrollTop = this._scrollBarRef._ps.contentHeight
      } else if (this.props.messages[0].cursor !== this.props.startCursor) {
        this._scrollBarRef._container.scrollTop = 0
      }
    }
  }

  renderMessageAttachments(message, isMessageSentByCurrent) {
    const areThereAttachments = message.attachments && message.attachments.edges.length

    if (areThereAttachments) {
      const attachmentMap = {}
      message.attachments.edges.forEach(attachment => {
        if (attachment.node) {
          attachmentMap[attachment.node.__typename] =
            attachmentMap[attachment.node.__typename] || []
          attachmentMap[attachment.node.__typename].push(attachment)
        }
      })

      const attachments = Object.keys(attachmentMap).map((type, index) => {
        switch (type) {
          case 'ReverseProspectRequest':
            return (
              <ReverseProspectRequestAttachment
                key={`attachment-${index}`}
                reverseProspectingRequest={attachmentMap[type][0].node}
                isMessageSentByCurrent={isMessageSentByCurrent}
              />
            )
          case 'Property':
            return (
              <div
                key={`attachment-${index}`}
                className={`flex-shrink-1 ${
                  isMessageSentByCurrent ? 'mr-3' : 'ml-3'
                } bg-lighter mt-2 rounded py-2 px-3 d-flex flex-column`}
              >
                <AttachedListings
                  attachments={attachmentMap[type]}
                  totalCount={message.attachments.total_count}
                  messageId={message.id}
                  isMessageSentByCurrent={isMessageSentByCurrent}
                  renderListingLink={this.props.renderListingLink}
                />
              </div>
            )
          case 'HomeReport':
            return (
              <div
                key={`attachment-${index}`}
                className={`flex-shrink-1 ${
                  isMessageSentByCurrent ? 'mr-3' : 'ml-3'
                } bg-lighter mt-2 rounded py-2 px-3 d-flex flex-column`}
              >
                <HomeReportAttachment homeReport={attachmentMap[type][0].node} />
              </div>
            )
          case 'MarketAlert': {
            const marketAlert = attachmentMap[type][0].node

            if (marketAlert.id) {
              return (
                <div
                  key={`attachment-${index}`}
                  className={`flex-shrink-1 ${
                    isMessageSentByCurrent ? 'mr-3' : 'ml-3'
                  } bg-lighter mt-2 rounded py-2 px-3 d-flex flex-column`}
                >
                  <MarketAlertAttachment marketAlert={marketAlert} />
                </div>
              )
            }
            break
          }
          case 'ShowingRequest': {
            const showingRequest = attachmentMap[type][0].node
            return (
              <div
                key={`attachment-${index}`}
                className={`flex-shrink-1 ${
                  isMessageSentByCurrent ? 'mr-3' : 'ml-3'
                } bg-lighter mt-2 rounded py-2 px-3 d-flex flex-column`}
              >
                <ShowingRequestAttachment
                  listingId={showingRequest.property.id}
                  listingSlug={showingRequest.property.slug}
                  propertyAddress={showingRequest.property.address}
                  listingPhotoUrl={showingRequest.property.photos.edges[0].node.photo_url}
                  renderListingLink={this.props.renderListingLink}
                />
              </div>
            )
          }
        }
      })

      return attachments
    }
  }

  renderMessage(message, isMessageSentByCurrent) {
    return (
      <div
        className={`${
          isMessageSentByCurrent
            ? 'chat-message-right align-items-end'
            : 'chat-message-left align-items-start'
        } ConversationMessages-message-width mb-4 d-flex flex-column`}
        key={message.id}
      >
        {message.body && (
          <div
            className={`flex-shrink-1 ${
              isMessageSentByCurrent ? 'bg-primary mr-3' : 'bg-lighter ml-3'
            } rounded py-2 px-3 d-flex flex-column`}
            style={{ whiteSpace: 'pre-line' }}
          >
            {message.body}
          </div>
        )}
        {this.renderMessageAttachments(message, isMessageSentByCurrent)}
        <div className="text-muted small text-nowrap mt-2 text-center mx-3">
          {TimeFormatter.formatUnix(message.created_at, 'monthDayAtTime')}
          {message.context === 'FUB_INBOX' && (<Box display='inline'> via <Box style={{ verticalAlign: 'top' }} component="img" alt="Follow Up Boss logo" src="https://d2ygk8gs8v5c6g.cloudfront.net/vendor_logos/fub_logo_2.png" width="30px" maxWidth="30px" objectFit="contain" /></Box>)}
        </div>
      </div>
    )
  }

  render() {
    const messages = reverse(slice(this.props.messages)) // reverse so the newest messages are on the bottom
    const { senderId } = this.props

    return (
      <Fragment key={this.props.participant.id}>
        <PerfectScrollbar
          className="w-100 ConversationMessages-scroll-height"
          ref={ref => {
            this._scrollBarRef = ref
          }}
        >
          <div className="container-fluid py-3">
            <div className="d-flex flex-row justify-content-center align-items-center mb-4">
              {this.props.areThereMoreMessages ? (
                <button
                  className="btn btn-outline-dark text-center"
                  onClick={this.props.fetchMoreMessages}
                  disabled={this.props.isQueryLoading || !this.props.areThereMoreMessages}
                >
                  {this.props.isQueryLoading ? 'Loading...' : 'Load more'}
                </button>
              ) : (
                <>
                  {messages.length > 5 && (
                    <button className="btn btn-outline-dark text-center" disabled>
                      All messages shown
                    </button>
                  )}
                </>
              )}
            </div>
            {messages.map(message =>
              this.renderMessage(message.node, message.node.sender.id === senderId)
            )}
          </div>
        </PerfectScrollbar>
      </Fragment>
    )
  }
}

export default withApollo(ConversationMessages)
