import { Component } from 'react';

class PhotoCircle extends Component{
  render(){
    return(
        <div
          className={`d-block rounded-circle PhotoCircle-photoCircle ${this.props.additionalClasses}`}
          style={{backgroundImage: `url(${this.props.photoUrl})`}}
        />
    )
  }
}

export default PhotoCircle;
