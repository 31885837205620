import { Component } from 'react';
import { Legend, LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, Dot, Label } from 'recharts'
import { determineIfIncompleteDataPoint, isSelectedEndInIncompleteInterval, formatXAxisLabel, formatTooltipTimeLabel } from '../Utils/ChartUtils'
import numeral from 'numeral'
import moment from 'moment-timezone'
import ErrorBoundarySimple from './ErrorBoundarySimple'
import Switcher from './Switcher'
import ActivityOverTimeTable from './ActivityOverTimeTable'
import { TabletAndDesktop } from './ResponsiveComponents'
class ActivityOverTimeLineChart extends Component {
  state = {
    hideCurrentInterval: true,
    showTable: false,
  }

  updateHideCurrentInterval = () => {
    this.setState({ hideCurrentInterval: !this.state.hideCurrentInterval })
  }

  customTooltip = ({ active, payload, label }) => {
    if (active) {
      if (payload && payload.length) {
        const payloadData = payload[0]
        const isIncompleteDataPoint = payloadData.payload.isIncompleteDataPoint
        const tooltipTimeLabel = payloadData.payload.tooltipTimeLabel

        return (
          <div className={`custom-tooltip ActivityOverTimeLineChart-tooltipContainer`}>
            <p className="recharts-tooltip-label">{tooltipTimeLabel}</p>
            <div className="d-flex flex-row">
              <p style={{ color: payloadData.stroke }}>
                {this.props.chartTitle}: {numeral(payloadData.value).format('0,0')}
              </p>
            </div>
            {isIncompleteDataPoint && <p className="text-muted">Incomplete data for selected range</p>}
          </div>
        )
      }
    }
    return null
  }

  customDot(props) {
    const color = props.stroke
    if (props.payload.isIncompleteDataPoint) {
      return <Dot {...props} stroke={color} fill={'#fff'} />
    } else {
      return <Dot {...props} stroke={'#fff'} fill={color} />
    }
  }

  customActiveDot(props) {
    const color = props.fill
    const radius = 5
    if (props.payload.isIncompleteDataPoint) {
      return <Dot {...props} stroke={color} fill={'#fff'} r={radius} />
    } else {
      return <Dot {...props} stroke={'#fff'} fill={color} r={radius} />
    }
  }

  formatActivityData(data) {
    const timezone = this.props.timezone || moment.tz.guess()
    let formattedData = data.map((activityData, index) => {
      return {
        start_time: formatXAxisLabel(activityData.start_time, this.props.dateRange.from, this.props.metricInterval, index === 0, timezone),
        tooltipTimeLabel: formatTooltipTimeLabel({
          startTime: activityData.start_time,
          selectedStart: this.props.dateRange.from,
          selectedEnd: this.props.dateRange.to,
          metricInterval: this.props.metricInterval,
          isFirstDataPoint: index === 0,
          isLastDataPoint: index === data.length - 1,
          timezone: timezone,
        }),
        metric_value: activityData.metric_value,
        isIncompleteDataPoint: determineIfIncompleteDataPoint(this.props.dateRange.from, this.props.dateRange.to, this.props.metricInterval, index, data.length),
      }
    })

    if (this.state.hideCurrentInterval && isSelectedEndInIncompleteInterval(this.props.dateRange.to, this.props.metricInterval)) {
      formattedData = formattedData.slice(0, formattedData.length - 1)
    }
    return formattedData
  }

  renderSwitcher() {
    return (
      <TabletAndDesktop>
        {matches => {
          return (
            <Switcher
              checked={this.state.hideCurrentInterval}
              onChange={() => this.updateHideCurrentInterval()}
              metricInterval={this.props.metricInterval}
              className={`col-12 col-sm-12 col-md-4 ${matches ? 'd-flex flex-row justify-content-end align-items-center' : 'mt-2'}`}
            />
          )
        }}
      </TabletAndDesktop>
    )
  }

  renderChart(viewData) {
    return (
      <div className="row d-flex flex-row justify-content-center">
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={viewData} margin={{ top: 10, right: 40, left: 10, bottom: 5 }}>
            <XAxis dataKey="start_time" />
            <YAxis tickFormatter={val => (val > 1 ? numeral(val).format('0,0') : val)}>{this.props.yAxisLabel && <Label value={this.props.yAxisLabel} position="insideBottomLeft" angle={-90} />}</YAxis>
            {!this.props.shouldHideLegend && <Legend />}
            <Tooltip content={this.customTooltip} />
            <Line name={this.props.chartTitle} type="monotone" dataKey="metric_value" stroke="#309FC6" dot={this.customDot} activeDot={this.customActiveDot} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    )
  }

  render() {
    const viewData = this.formatActivityData(this.props.data)

    if (this.props.displayOnlyChart) {
      return (
        <TabletAndDesktop>
          {matches => {
            return (
              <ErrorBoundarySimple isAgentErrorBoundary>
                <div>
                  <div className={`row ${this.props.lineChartClasses ? this.props.lineChartClasses : 'line-chart'}`}>
                    {matches && <div className="col-md-8" />}
                    {isSelectedEndInIncompleteInterval(this.props.dateRange.to, this.props.metricInterval) && this.renderSwitcher()}
                  </div>
                  {this.renderChart(viewData)}
                </div>
              </ErrorBoundarySimple>
            )
          }}
        </TabletAndDesktop>
      )
    }

    return (
      <ErrorBoundarySimple isAgentErrorBoundary>
        <div className="d-flex flex-column w-100">
          <div className={`card mb-4 ${this.props.lineChartClasses ? this.props.lineChartClasses : 'line-chart'}`}>
            <div className="card-header p-4">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-8 d-flex flex-column justify-content-center align-items-start">
                  <div className="text-big font-weight-bold">{this.props.title}</div>
                  {this.props.subtitle && <div className="text-muted">{this.props.subtitle}</div>}
                </div>
                {isSelectedEndInIncompleteInterval(this.props.dateRange.to, this.props.metricInterval) && this.renderSwitcher()}
              </div>
            </div>
            <div className="card-body">{this.renderChart(viewData)}</div>
            {this.props.footer && <div className="card-footer">{this.props.footer}</div>}
            {!this.state.showTable && (
              <div className="card-footer">
                <button className="btn btn-sm" onClick={() => this.setState({ showTable: true })}>
                  Show/Export Raw Data
                </button>
              </div>
            )}
            {this.state.showTable && (
              <div className="card-footer">
                <ActivityOverTimeTable data={this.props.data} metricInterval={this.props.metricInterval} title={this.props.title} />
              </div>
            )}
          </div>
        </div>
      </ErrorBoundarySimple>
    )
  }
}

export default ActivityOverTimeLineChart
