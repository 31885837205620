import { useContext } from 'react'
import { CurrentUserContext } from '../../Homebuyer/Contexts/CurrentUserContext'

const HomeReportAttachment = ({ homeReport }) => {
  const userContext = useContext(CurrentUserContext)

  console.log(homeReport)
  const linkAddress = userContext?.loggedIn ? `/homesearch/home-reports/${homeReport.id}` : `/agent/users/${homeReport.user.id}/home-reports/${homeReport.id}`

  return (
    <div key={homeReport.id} className="d-flex flex-row w-100 justify-content-center align-items-center text-dark">
      <a href={linkAddress}>Home Value Alert for {homeReport.address}</a>
    </div>
  )
}

export default HomeReportAttachment
