import { DataGridPro, GridToolbar, GridToolbarContainer, GridToolbarExport  } from '@mui/x-data-grid-pro';
import TimeFormatter from "../Utils/TimeUtils";

const ActivityOverTimeTable = ({data, metricInterval, title}) => {

  let timeHeader = metricInterval === 'WEEK' ? 'Week of' : 'Month'

  const columns = [
    {
      field: 'start_time',
      headerName: timeHeader,
      flex: 1,
      valueGetter: (params) => TimeFormatter.format(params.row.start_time * 1000, 'monthDayYearAbbr')
    },
    {
      field: 'metric_value',
      headerName: title,
      flex: 1
    },
  ]

  return(
    <>
      <DataGridPro
        rows={data}
        columns={columns}
        pagination
        initialState={{
          pagination: {
            pageSize: 100,
          },
        }}
        density="compact"
        disableSelectionOnClick
        getRowId={(row) => row.start_time}
        autoHeight={true}
        rowsPerPageOptions={[50, 100]}
        components={{
          Toolbar: () => (
            <GridToolbarContainer>
              <GridToolbarExport csvOptions={{fileName: title}} printOptions={{disableToolbarButton: true}}/>
            </GridToolbarContainer>
          )
        }}
      />
      <small>Note: This table/export includes data from incomplete time-periods</small>
    </>
  )
}

export default ActivityOverTimeTable;