import {
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  FormHelperText
} from "@mui/material";
import {STATE_ABBREVIATIONS} from "../Utils/GeoUtils";
import React, { useRef } from "react";

const SelectState = ({onChange, className, valueProp, setValueProp, error}) => {
  const pressedKeys = useRef('')

  const handleKeyPress = (event) => {
    const key = event.key
    if (pressedKeys.current.length >= 2) {
      pressedKeys.current = ''
    }
    pressedKeys.current += key

    const foundState = STATE_ABBREVIATIONS.find((item) => item.indexOf(pressedKeys.current.toUpperCase()) === 0)
    if (foundState) {
      setValueProp(foundState)
      onChange(foundState)
    }
  }

  const handleBlur = () => {
    pressedKeys.current = ''
  }

  const handleChange = (event) => {
    onChange(event.target.value)
  }

  return (
    <FormControl fullWidth error={error ? true : false}>
      <InputLabel id="state-select-label">
        State
      </InputLabel>
      <Select
        value={valueProp}
        variant='outlined'
        labelId='state-select-label'
        label='State'
        id='state-select'
        className={className}
        onKeyPress={handleKeyPress}
        onBlur={handleBlur}
        onChange={handleChange}
      >
        {
          STATE_ABBREVIATIONS.map((stateAbbr) => <MenuItem key={stateAbbr} value={stateAbbr}>{stateAbbr}</MenuItem>)
        }
      </Select>
      {
        error && <FormHelperText>{ error }</FormHelperText>
      }

    </FormControl>
  )
}

export default SelectState
