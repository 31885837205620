import { areCookiesEnabled } from './../../Utils/CookieUtils'
import store from 'store'
import { Component, createContext } from 'react';

let CurrentUserContext
const { Provider, Consumer } = (CurrentUserContext = createContext())

class CurrentUserProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      viewType: 'CARD',
      mobileViewType: 'CARD',
      showingSignUp: false,
      loggedIn: this.props.logged_in && areCookiesEnabled(),
    }
  }

  changeViewType = (viewType, isMobile = false) => {
    if (isMobile) {
      this.setState({ mobileViewType: viewType })
    } else {
      store.set('viewType', viewType)
      this.setState({ viewType })
    }
  }

  showingSignUp = () => {
    if (this.props.logged_in) {
      return false
    } else {
      return this.state.showingSignUp
    }
  }

  showForcedRegistration = () => {
    if (this.props.logged_in) {
      return false
    } else {
      return this.props.display_rules.registration_required
    }
  }

  showSignUp = () => {
    this.setState({ showingSignUp: true })
  }

  closeSignUp = () => {
    this.setState({ showingSignUp: false })
  }

  render() {
    return (
      <Provider
        value={{
          agent: this.props.agent,
          displayRules: this.props.display_rules,
          disclaimers: this.props.disclaimers,
          exclusivesDisclaimer: this.props.exclusives_disclaimer,
          loggedIn: this.state.loggedIn,
          env: this.props.env,
          changeViewType: this.changeViewType,
          viewType: this.state.viewType,
          mobileViewType: this.state.mobileViewType,
          showingSignUp: this.showingSignUp(),
          showSignUp: this.showSignUp,
          showForcedRegistration: this.showForcedRegistration(),
          closeSignUp: this.closeSignUp,
          blockData: !this.props.logged_in && this.props.display_rules.registration_required,
        }}
      >
        {this.props.children}
      </Provider>
    )
  }
}

export { CurrentUserProvider, Consumer as CurrentUserConsumer, CurrentUserContext }
