import { Box, Card, Typography, Skeleton } from '@mui/material'
import { gql } from '@apollo/client'

export const LISTINGS_METRICS_FRAGMENT = gql`
  fragment ListingsMetrics on MarketActivityAnalytics {
    avg_list_price
    avg_sold_price
    avg_listing_price_per_sqft
    avg_sold_price_per_sqft
    recently_sold_listings_total_count
    recently_updated_listings_total_count
  }
`

const ListingsMetrics = ({ title, value, loading }) => (
  <Card sx={{ boxShadow: 'none' }}>
    <Box display="flex" justifyContent="center">
      <Box py={1.5} px={2} display="flex" flexDirection="column" alignItems="center">
        <Box>{loading ? <Skeleton height={36} /> : <Typography variant="h3">{value.toLocaleString()}</Typography>}</Box>
        <Typography fontWeight="bold" color="text.secondary" textAlign="center">
          {title}
        </Typography>
      </Box>
    </Box>
  </Card>
)

export default ListingsMetrics
