import {Link, withRouter} from "react-router-dom";
import {
  IoIosArrowRoundBack
} from "react-icons/io";
import PhotoCircle from "./PhotoCircle";
import { Component } from 'react';
import { MobileAndTablet } from '../ResponsiveComponents'

class ConversationHeader extends Component {

  render() {
    const showBackButton = this.props.match.path === '/:id';
    const participant = this.props.participant;

    return (
      <>
        <MobileAndTablet>
          {(matches) => {
            return (
              <div
                className={`py-3 pr-4 pl-lg-4 d-flex flex-row justify-content-start align-items-center ConversationHeader-header-height`}
              >
                {matches && showBackButton &&
                <Link
                  to={{pathname: `/`}}
                  className="border-light border-right d-flex flex-row justify-content-center align-items-center text-dark px-2 mr-2"
                >
                  <IoIosArrowRoundBack
                    size={20}
                  />
                  Back
                </Link>
                }
                <div className="media d-flex flex-row justify-content-start align-items-center pl-2 mt-0">
                  {this.props.participantPhotoUrl &&
                  <PhotoCircle
                    photoUrl={this.props.participantPhotoUrl}
                    additionalClasses="mr-3"
                  />
                  }
                  <div className="media-body">
                    <div className="text-truncate">
                      <strong>{participant.full_name}</strong>
                    </div>
                    {this.props.children}
                  </div>
                </div>
              </div>
            )
          }}
        </MobileAndTablet>
      </>
    );
  }
}

export default withRouter(ConversationHeader);
