import React from 'react'
import { Button } from '@mui/material'
import GridIcon from '@mui/icons-material/GridView'

const SeePhotosButton = ({ photoCount, onClick }) => {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      color="primary"
      size="large"
      startIcon={<GridIcon />}
      sx={theme => ({
        position: 'absolute',
        bottom: theme.spacing(3),
        right: theme.spacing(6),
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.primary,
        borderColor: theme.palette.grey[800],
        boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.3)',
        borderWidth: '1px',
        borderStyle: 'solid',
        '&:hover': {
          backgroundColor: theme.palette.grey[100],
          boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.4)',
        },
      })}
    >
      See all {photoCount} photos
    </Button>
  )
}

export default SeePhotosButton
