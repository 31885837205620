import React, { useState, Fragment } from 'react'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery, Box } from '@mui/material'
import PhotoLightbox from './PhotoLightbox'
import PhotoGrid from './Photos/PhotoGrid'
import MobilePhotoCarousel from './Photos/MobilePhotoCarousel'

const ListingPhotos = ({ photos }) => {
  const [photoIndex, setPhotoIndex] = useState(null)
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)
  const theme = useTheme()
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'))

  const onCloseLightbox = () => {
    setIsLightboxOpen(false)
    setPhotoIndex(null)
  }

  const handlePhotoClick = index => {
    setPhotoIndex(index)
    setIsLightboxOpen(true)
  }

  return (
    <>
      <PhotoLightbox {...{ photos, photoIndex, isLightboxOpen, onCloseLightbox }} />
      {isSmallerThanMd ? (
        <MobilePhotoCarousel photos={photos} />
      ) : (
        <Box
          sx={theme => ({
            pt: 3,
            backgroundColor: theme.palette.common.white,
            position: 'relative',
          })}
          className="photo-grid"
        >
          <PhotoGrid photos={photos} onPhotoClick={handlePhotoClick} />
        </Box>
      )}
    </>
  )
}

export default ListingPhotos
