import capitalize from "lodash/capitalize";
import { Component } from 'react';

class Switcher extends Component {

  render() {
    const checked = this.props.checked;

    return (
      <div className={this.props.className ? this.props.className : "col-2 d-flex flex-row justify-content-end align-items-center"}>
        <label className="switcher">
          <input
            type="checkbox"
            className="switcher-input"
            checked={checked}
            onChange={this.props.onChange}
          />
          <span className="switcher-indicator" style={{ background: checked ? '#309FC6' : ''}}>
            <span className="switcher-yes"></span>
            <span className="switcher-no"></span>
          </span>
        </label>
        <div className="switcher-label">Hide Incomplete {capitalize(this.props.metricInterval)}</div>
      </div>
    )
  }
}

export default Switcher
