export const areCookiesEnabled = () => {
  let cookieEnabled = navigator.cookieEnabled;
  if (!cookieEnabled){
    document.cookie = "testcookie";
    cookieEnabled = document.cookie.indexOf("testcookie") !== -1;
  }
  return cookieEnabled
};

export const getCurrentMembershipFromCookies = (agentId, alertOptionsForMLS) =>  {
  try {
    if (areCookiesEnabled()) {
      const cookies = document.cookie.split(';').map(cookie => cookie.trim());
      if (cookies) {
        const membershipCookie = cookies.find((cookie) => cookie.startsWith(`current_membership_${agentId}`));
        const mlsClassCookie = cookies.find((cookie) => cookie.startsWith(`mls_class_${agentId}`));

        if (membershipCookie && mlsClassCookie) {
          const membership = membershipCookie.split('=')[1];
          const mlsClass = mlsClassCookie.split('=')[1];
          const cookieValue = `${membership}-${mlsClass}`;

          if (alertOptionsForMLS[membership]) {
            const selectedAlertOption = alertOptionsForMLS[membership].find(option => option[1] === cookieValue);
            if (selectedAlertOption) {
              return {
                label: selectedAlertOption[0],
                value: selectedAlertOption[1]
              }
            }
          }
        }
      }
    }

    const firstKey = Object.keys(alertOptionsForMLS)[0];
    const firstAlertOption = alertOptionsForMLS[firstKey][0];
    return {
      label: firstAlertOption[0],
      value: firstAlertOption[1]
    };

  } catch (error) {
    const firstKey = Object.keys(alertOptionsForMLS)[0];
    const firstAlertOption = alertOptionsForMLS[firstKey][0];
    return {
      label: firstAlertOption[0],
      value: firstAlertOption[1]
    };
  }
};