// Listing status, corresponds to PropertyType#display_status or PropertyType#simple_listing_status
const FOR_SALE = 'For Sale'
const FOR_RENT = 'For Rent'
const SOLD = 'Sold'
const RENTED = 'Rented'
const COMING_SOON = 'Coming Soon'
const EXCLUSIVE = 'Exclusive'
const IN_CONTRACT = 'In Contract'
const INQUIRE = 'Inquire'
const UNKNOWN = 'Unknown'
const OFF_MARKET = 'Off Market'

export {
  FOR_SALE,
  FOR_RENT,
  SOLD,
  RENTED,
  COMING_SOON,
  EXCLUSIVE,
  IN_CONTRACT,
  INQUIRE,
  UNKNOWN,
  OFF_MARKET,
}
