import { useContext } from 'react'
import { CurrentUserContext } from '../../Homebuyer/Contexts/CurrentUserContext'

const MarketAlertAttachment = ({ marketAlert }) => {
  const userContext = useContext(CurrentUserContext)
  const linkAddress = userContext?.loggedIn ? `/homesearch/market-activity-alerts/${marketAlert.id}` : `/agent/users/${marketAlert.user.id}/market-activity-alerts/${marketAlert.id}`

  return (
    <div key={marketAlert.id} className="d-flex flex-row w-100 justify-content-center align-items-center text-dark">
      <a href={linkAddress}>Market Alert for {marketAlert.market_name}</a>
    </div>
  )
}

export default MarketAlertAttachment
