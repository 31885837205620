import { GeoJSON, LayersControl, Map, Marker, TileLayer, ZoomControl } from 'react-leaflet'
import { MAP_ATTRIBUTION, SATELLITE_TILE_URL, TILE_URL } from '../Utils/MapUtils'
import LeafletHeatmapOverlay from '~/components/Common/LeafletHeatmapOverlay'
import Control from 'react-leaflet-control'
import { Component } from 'react';
import { gql } from '@apollo/client'
import { IoIosStar } from 'react-icons/io'
import { divIcon } from 'leaflet'
import ReactDOMServer from 'react-dom/server'
import { CircularProgress } from '@mui/material'

export const HEATMAP_FRAGMENT = gql`
  fragment Heatmap on MarketActivityAnalytics {
    heatmap_data {
      latitude
      longitude
      count
    }
  }
`

class Heatmap extends Component {
  componentDidMount() {
    const map = this.refs.map.leafletElement

    let bounds = L.geoJSON(this.props.geoJson).getBounds()
    map.fitBounds(bounds)

    if (!this.props.data) return

    const mapPoints = this.props.data
      .filter(point => point.latitude !== 0.0)
      .map(point => {
        return { lat: point.latitude, lng: point.longitude, count: point.count }
      })

    const cfg = {
      // radius should be small ONLY if scaleRadius is true (or small radius is intended)
      // if scaleRadius is false it will be the constant radius used in pixels
      maxOpacity: 0.6,
      radius: 20,
      // scales the radius based on map zoom
      scaleRadius: false,
      // if set to false the heatmap uses the global maximum for colorization
      // if activated: uses the data maximum within the current map boundaries
      //   (there will always be a red spot with useLocalExtremas true)
      // which field name in your data represents the latitude - default "lat"
      latField: 'lat',
      // which field name in your data represents the longitude - default "lng"
      lngField: 'lng',
      // which field name in your data represents the data value - default "value"
      valueField: 'count',
    }
    const heatmapLayer = new LeafletHeatmapOverlay(cfg)
    heatmapLayer.setData({
      data: mapPoints,
    })
    map.addLayer(heatmapLayer)
    if (mapPoints.length > 1) {
      map.fitBounds(mapPoints.map(point => [point.lat, point.lng]))
    }
  }

  render() {
    const height = this.props.height || 400

    const targetPropertyIcon = divIcon({
      type: 'div',
      className: `search-marker`,
      popupAnchor: [2, -25],
      iconSize: [20, 20],
      iconAnchor: [21, 21],
      html: ReactDOMServer.renderToString(<IoIosStar style={{ height: '20px', width: '20px' }} />),
    })

    return (
      <Map center={[0, 0]} zoom={10} zoomControl={false} style={{ height: height, zIndex: 0 }} scrollWheelZoom={false} ref="map" id="market-explorer-map">
        {this.props.loading && (
          <Control position="topleft">
            <CircularProgress />
          </Control>
        )}
        {!this.props.loading && (
          <Control position="topleft">
            <div className={`leaflet-control-layers py-1 px-2 d-flex flex-row align-items-center`} style={{ backgroundColor: 'white', color: '111111' }}>
              <small className="mb-0 text-dark">Colors indicate relative popularity</small>
            </div>
          </Control>
        )}
        <ZoomControl position="topleft" />

        <LayersControl position="topright">
          <LayersControl.BaseLayer name="Streets" checked={true}>
            <TileLayer attribution={MAP_ATTRIBUTION} url={TILE_URL} />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Satellite">
            <TileLayer attribution={MAP_ATTRIBUTION} url={SATELLITE_TILE_URL} />
          </LayersControl.BaseLayer>
        </LayersControl>
        <GeoJSON
          data={this.props.geoJson}
          style={() => ({
            color: '#575757',
            weight: 2,
            fillOpacity: 0,
          })}
        />
        {this.props.listingMarkerCenter && <Marker position={this.props.listingMarkerCenter} icon={targetPropertyIcon} />}
      </Map>
    )
  }
}

export default Heatmap
